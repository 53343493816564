import { Routes } from '@angular/router';

import { BaseComponent } from './views/layout/base/base.component';

export const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./views/home/home.component').then((m) => m.HomeComponent),
      },
    ],
  },
];
