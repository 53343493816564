<footer class="testing-ci-cd">
  <section class="items-container">
    <nav class="links-item">
      <div class="body">
        <li class="link">
          <img src="svgs/phone-number.svg" alt="" />
          <a href="tel:+ 962 7 9936 7271">+962 7 9936 7271</a>
        </li>
        <li class="link">
          <img src="svgs/email.svg" alt="" />
          <a href="mailto:info@code-mint.com">info&#64;code-mint.com</a>
        </li>

        <li class="link">
          <img src="svgs/location-pin.svg" alt="" />
          <a
            href="https://www.google.com/maps/place/%D9%85%D9%81%D8%B1%D9%88%D8%B4%D8%A7%D8%AA+%D9%82%D9%86%D8%AF%D9%8A%D9%84%E2%80%AD/@31.9964061,35.8835075,15z/data=!4m6!3m5!1s0x151ca027c17d570f:0xf42375d447ccd44f!8m2!3d31.9964061!4d35.8835075!16s%2Fg%2F12qf_0wfl?entry=ttu&g_ep=EgoyMDI0MTIwNC4wIKXMDSoASAFQAw%3D%3D"
            >Queen Rania St, Amman</a
          >
        </li>
      </div>
    </nav>
    <div class="socials">
      <a href="https://www.linkedin.com/company/code-mint/"
        ><img src="svgs/linked-in.svg" alt=""
      /></a>
      <a href="https://www.instagram.com/code.mint?igsh=YjZ6amo3bDJjbWhr"
        ><img src="svgs/instagram.svg" alt=""
      /></a>
      <a href="https://www.facebook.com/share/15fEeBZwws/?mibextid=LQQJ4d"
        ><img src="svgs/facebook.svg" alt=""
      /></a>
      <a href="https://x.com/codemintt"><img src="svgs/x.svg" alt="" /></a>
    </div>
  </section>
  <section class="ending">
    <hr />
    <p>{{ "footer.copyRight" | translate }} © Code Mint 2024</p>
  </section>
</footer>
