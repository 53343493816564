<header class="header-container">
  <div class="logo-container">
    <figure routerLink="/">
      <img src="svgs/company-logo.svg" alt="logo" class="logo" />
    </figure>
  </div>
  <nav class="links-container">
    <ul class="links">
      <li class="link">
        <a href="/">{{ "header.home" | translate }}</a>
      </li>

      <li class="link">
        <a [href]="services">{{ "header.services" | translate }}</a>
      </li>
      <li class="link">
        <a [href]="process"> {{ "header.workProcess" | translate }}</a>
      </li>

      <li class="link">
        <a [href]="clients">{{ "header.clients" | translate }}</a>
      </li>
      <li class="link">
        <a>EduMinty</a>
      </li>
    </ul>
  </nav>
  <div class="actions-container">
    <figure (click)="this.changeLanguage()">
      <img
        src="svgs/world.svg"
        alt="change language icon"
        style="width: 30px"
      />
    </figure>

    <div class="divider"></div>
    <button class="get-app">
      <a [href]="contactUs">{{ "header.getInTouch" | translate }}</a>
    </button>
  </div>

  <div class="mobile-menu-toggle">
    <figure>
      <img
        [src]="'svgs/' + (isMobileMenuOpen ? 'close' : 'menu') + '.svg'"
        alt="mobile menu toggle"
        (click)="this.toggleMobileMenu()"
      />
    </figure>
  </div>
  <nav
    class="mobile-menu"
    [style.display]="isMobileMenuOpen ? 'block' : 'none'"
  >
    <ul aria-label="Primary" role="list" class="nav-list">
      <li>
        <a
          class="link"
          href="/"
          routerLinkActive="active"
          ariaCurrentWhenActive="page"
          >{{ "header.home" | translate }}</a
        >
        <hr />
      </li>

      <li>
        <a
          [href]="services"
          class="link"
          routerLinkActive="active"
          ariaCurrentWhenActive="page"
          >{{ "header.services" | translate }}</a
        >
        <hr />
      </li>
      <li>
        <a
          [href]="process"
          class="link"
          routerLinkActive="active"
          ariaCurrentWhenActive="page"
          >{{ "header.workProcess" | translate }}</a
        >
        <hr />
      </li>

      <li>
        <a
          [href]="clients"
          class="link"
          routerLinkActive="active"
          ariaCurrentWhenActive="page"
          >{{ "header.clients" | translate }}</a
        >
        <hr />
      </li>
      <li>
        <a class="link" routerLinkActive="active" ariaCurrentWhenActive="page"
          >EduMinty</a
        >
        <hr />
      </li>
      <li>
        <a
          class="link"
          [href]="contactUs"
          routerLinkActive="active"
          ariaCurrentWhenActive="page"
          >{{ "header.getInTouch" | translate }}
        </a>
        <hr />
      </li>
      <li class="mobile-language" (click)="this.changeLanguage()">
        <figure>
          <img src="svgs/world.svg" alt="change language icon" />
        </figure>
      </li>
    </ul>
  </nav>
</header>
